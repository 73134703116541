<template>
  <div class="sliderMenuContainer" :class="isLandscape ? 'heightLandscape' : 'heightPortrait'">
    <div
      v-for="(promoTools, index) of promoToolsData"
      class="buttonContainer"
      :key="index"
    >
      <ButtonMenu
        v-if="Math.floor(index / 4) === selectedPage"
        :key="promoTools.appName"
        :style="buttonWidth"
        :promoTools="promoTools"
        :active="promoTools.appName === promoToolActive"
        @selectApp="handleClickButtonMenu"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from '@vue/runtime-core';
import ButtonMenu from './ButtonMenu.vue';
export default {
  name: 'SliderMenu',
  props: {
    promoToolsData: Array,
    isLandscape: Boolean
  },
  components: {
    ButtonMenu,
  },
  setup(props) {
    const store = useStore();
    const selectedPage = computed(() => store.state.selectedPage);
    const promoToolActive = computed(() => store.state.promoToolActive);

    const buttonWidth = computed(() => {
      const maxLength = props.promoToolsData.length;
      return `width: ${ 100 / (maxLength > 4 ? 4 : maxLength) }%`;
    });

    const handleClickButtonMenu = (name) => {
      store.dispatch('setPromoToolActive', name);
      if(!props.isLandscape) {
        store.dispatch('setShowMenu', false);
      }
    };

    return {
      selectedPage,
      promoToolActive,
      buttonWidth,
      handleClickButtonMenu,
    }
  }
}
</script>

<style>
.sliderMenuContainer {
  display: flex;
  flex-direction: row;
  touch-action: none;
}
.buttonContainer {
  display: contents;
}
.heightLandscape {
  height: 8%;
}
.heightPortrait {
  height: 50%;
}
</style>
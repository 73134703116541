export const EVENT_ACTIVE_STATUS_CHANGED = "activeStatusChanged";
export const EVENT_ANALYTICS = "analytics";
export const EVENT_AUTOPLAY_ACTIVATED_GAME = "autoplayActivatedGame";
export const EVENT_AUTOPLAY_CANCELLED = "autoplayCancelled";
export const EVENT_BLOCK_GUI_STATUS_CHANGED = "blockGUIStatusChanged";
export const EVENT_CURRENCY_FORMAT = "currencyFormat";
export const EVENT_ENABLE_PLAYER_BUTTONS = "enablePlayerButtons";
export const EVENT_ERROR = "error";
export const EVENT_GAME_CONTAINER_UPDATED = "gameContainerUpdated";
export const EVENT_GAME_READY_TO_PLAY = "gameReadyToPlay";
export const EVENT_INITIALIZE = "initialize";
export const EVENT_JACKPOT_UPDATED = "jackpotUpdated";
export const EVENT_PERFORM_UPDATE_BALANCE = "performUpdateBalance";
export const EVENT_PLAY_ENDED = "playEnded";
export const EVENT_PLAY_SOUND = "playSound";
export const EVENT_PLAY_STARTED = "playStarted";
export const EVENT_PRELOAD_PROGRESS = "preloadProgress";
export const EVENT_PUBLISH_FATAL_ERROR = "publishFatalError";
export const EVENT_REELS_STOPPED = "reelsStopped";
export const EVENT_RG2XC_GAME_LOADER_READY = "rg2xcGameLoaderReady";
export const EVENT_RGS_RESPONSE = "rgsResponse";
export const EVENT_SLOT_CONTAINER_UPDATED = "gameContainerUpdated";
export const EVENT_SIDE_PANEL = 'sidePanelData';
export const EVENT_UPDATE_BALANCE = "updateBalance";
export const EVENT_WINDOW_INITIALIZED = "windowInitialized";
export const EVENT_TOTAL_BET_UPDATED = "totalBetUpdated";
export const EVENT_TOGGLE_SIDEPANEL_STATE = "toggleSidePanelState";

export const DESKTOP = 'desktop';
export const MOBILE = 'mobile';
export const KIOSK_DEFAULT = 'kiosk';
export const KIOSK_NOTOUCH = 'kiosknotouch';

export const CHANNELS = { DESKTOP, MOBILE, KIOSK_DEFAULT, KIOSK_NOTOUCH };

export const CONSTANTS = {
  EVENTS_GMAPI: [
    EVENT_ACTIVE_STATUS_CHANGED,
    EVENT_ANALYTICS,
    EVENT_AUTOPLAY_ACTIVATED_GAME,
    EVENT_AUTOPLAY_CANCELLED,
    EVENT_BLOCK_GUI_STATUS_CHANGED,
    EVENT_CURRENCY_FORMAT,
    EVENT_ENABLE_PLAYER_BUTTONS,
    EVENT_ERROR,
    EVENT_GAME_CONTAINER_UPDATED,
    EVENT_GAME_READY_TO_PLAY,
    EVENT_INITIALIZE,
    EVENT_JACKPOT_UPDATED,
    EVENT_PERFORM_UPDATE_BALANCE,
    EVENT_PLAY_ENDED,
    EVENT_PLAY_SOUND,
    EVENT_PLAY_STARTED,
    EVENT_PRELOAD_PROGRESS,
    EVENT_PUBLISH_FATAL_ERROR,
    EVENT_REELS_STOPPED,
    EVENT_RG2XC_GAME_LOADER_READY,
    EVENT_RGS_RESPONSE,
    EVENT_SLOT_CONTAINER_UPDATED,
    EVENT_SIDE_PANEL,
    EVENT_UPDATE_BALANCE,
    EVENT_WINDOW_INITIALIZED,
    EVENT_TOTAL_BET_UPDATED,
    EVENT_TOGGLE_SIDEPANEL_STATE
  ],
  LANDSCAPE_SCREEN: 'landscape',
  PORTRAIT_SCREEN: 'portrait',
};

export default CONSTANTS
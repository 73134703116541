<template>
  <div class="notificationBar">
    {{ msgFromWidget }}
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from '@vue/runtime-core';
export default {
  name: 'NotificationBar',
  props: {
    isLandscape: Boolean
  },
  setup() {
    const store = useStore();
    const msgFromWidget = computed(() => store.state.messageFromWidget);

    return {  
      msgFromWidget
    } 
  }
}
</script>

<style>
.notificationBar {
  background-color: var(--blackColor);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15%;
  touch-action: none;
}
</style>
<template>
  <div class="headerContainer" :class="headerStyle">
    <ButtonHearder
      id="backButton"
      v-if="showButtons"
      v-show="!isLandscape && !isShowingMenu && !isShowingHelp"
      :icon="backSourceIcon"
      :isLandscape="isLandscape"
      @click="$emit('backMenu')"
      alt="Back Icon"
    />
    <ButtonHearder
      id="helpButton"
      v-show="!isLandscape && !isShowingMenu || isLandscape"
      :icon="toggleHelpIcon"
      :isLandscape="isLandscape"
      :isActive="isShowingHelp"
      @click="handleHelpButtonClick"
      alt="Help Icon"
    />
    <div class="titleContainer">
      {{ notification }}
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/runtime-core';
import ButtonHearder from './ButtonHeader.vue';
import helpIcon from '../assets/images/help.png';
import backIcon from '../assets/images/back.png';
import closeIcon from '../assets/images/close.png';
export default {
  name: 'Header',
  emits: ['backMenu', 'showHelp'],
  props: {
    notification: String,
    isLandscape: Boolean,
    isShowingMenu: Boolean,
    showButtons: Boolean
  },
  components: {
    ButtonHearder,
  },
  setup(props, { emit }) {
    const isShowingHelp = ref(false);
    const toggleHelpIcon = ref(helpIcon);
    const backSourceIcon = backIcon;

    const headerStyle = computed(() => {
      return (props.isLandscape ? 'isLandscape' : 'isPortrait' + (props.isShowingMenu ? ' showingMenu' : '')) + (isShowingHelp.value ? ' showingHelp' : '');
    });

    const handleHelpButtonClick = () => {
      isShowingHelp.value = !isShowingHelp.value;
      toggleHelpIcon.value = isShowingHelp.value ? closeIcon : helpIcon;
      emit('showHelp');
    }

    return {  
      backSourceIcon, 
      toggleHelpIcon,
      isShowingHelp,
      headerStyle,
      handleHelpButtonClick
    }
  }
}
</script>

<style>
.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--greyColor);
  touch-action: none;
  pointer-events: none;
  position: absolute;
}
.isLandscape {
  height: 9%;
}
.isPortrait {
  height: 20%;
}
.showingHelp {
  z-index: 5;
}
.showingMenu {
  position: relative !important;
}
.titleContainer {
  margin: 10px;
  font-size: 1.1rem;
  word-break: break-all;
  color: #FFFFFF;
}
</style>
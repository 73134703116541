<template>
  <div class="openPanelButton" :class="styleOpenButton" @click="handleOpenPanel" v-show="!keepOnScreen && touchSidePanel || !touchSidePanel">
    <img :src="iconButton" class="iconButton" :class="iconDirection" />
  </div>
</template>
  
<script>
import { useStore } from 'vuex';
import { computed, ref, watch } from '@vue/runtime-core';
import spOpenIcon from '../assets/images/sp_open.svg';
export default {
  name: 'OpenCloseButton',
  props: {
    isLandscape: Boolean,
  },
  setup(props) {
    const store = useStore();
    const iconButton = spOpenIcon;
    const iconDirection = ref('');
    const styleOpenButton = ref('');
    const openPanel = computed(() => store.state.openPanel);
    const keepOnScreen = computed(() => store.state.keepOnScreen);
    const touchSidePanel = computed(() => store.state.touchSidePanel);

    watch(openPanel, () => {
      iconDirection.value = getIconDirection();
      styleOpenButton.value = changeOpenButton();
    });

    watch(() => props.isLandscape, () => {
      iconDirection.value = getIconDirection();
      styleOpenButton.value = changeOpenButton();
    });

    const handleOpenPanel = () => {
      store.dispatch('setOpenPanel', !openPanel.value);
    };

    const getIconDirection = () => {
      let direction = 'iconDirection_';
      if(props.isLandscape) {
        direction += (openPanel.value) ? '0' : '180';
      }
      else {
        direction += (openPanel.value) ? '90' : '270';
      }
      return direction;
    };

    const changeOpenButton = () => {
      return (props.isLandscape ? 'openButtonLandscape' : 'openButtonPortrait') + 
             (openPanel.value ? '' : ' openButtonClosed');
    };

    return {
      iconButton,
      iconDirection,
      keepOnScreen,
      touchSidePanel,
      styleOpenButton,
      handleOpenPanel
    }
  }
}
</script>
  
<style>
.openPanelButton {
  display: flex;
  position: fixed;
  align-items: center;
  justify-items: center;
  justify-content: center;
  background-color: var(--greyColor);
  cursor: pointer;
  -webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
  user-select: none;
  pointer-events: auto;
}

.openPanelButton:hover {
  background-color: var(--greyLighColor);
}

.openButtonLandscape {
  width: 2vw;
  height: 8vh;
  border-top-right-radius: 8vh;
  border-bottom-right-radius: 8vh;
  right: -10%;
  bottom: 17%;
}

.openButtonPortrait {
  width: 10vw;
  height: 8% !important;
  border-bottom-left-radius: 12vw;
  border-bottom-right-radius: 12vw;
  right: 0;
  left: 0;
  margin: auto;
  bottom: -8%;
  opacity: 0.9;
}

.openButtonClosed {
  animation: colorchange 0.4s linear 2s 4, heartbeat 0.4s linear 2s 4;
}

.iconButton {
  -webkit-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	-khtml-user-drag: none;
}

.iconDirection_0 {
  transform: rotate(0deg);
  transition: all 1s ease;
  height: 36%;
  margin-right: 10%;
}

.iconDirection_90 {
  transform: rotate(90deg);
  transition: all 1s ease;
  height: 80%;
  margin-bottom: 10%;
}

.iconDirection_180 {
  animation: iconheartbeat_180 0.4s linear 2s 4;
  transform: rotate(180deg);
  transition: all 1s ease;
  height: 36%;
  margin-right: 10%;
}

.iconDirection_270 {
  animation: iconheartbeat_270 0.4s linear 2s 4;
  transform: rotate(270deg);
  transition: all 1s ease;
  height: 80%;
}

@keyframes colorchange {
  50% { background: var(--redColor); }
}

@keyframes heartbeat {
  50% { transform: scale(1.2); }
}

@keyframes iconheartbeat_180 {
  50% { transform: scale(1.2), rotate(180deg); }
}

@keyframes iconheartbeat_270 {
  50% { transform: scale(1.2), rotate(270deg); }
}
</style>
<template>
  <div class="buttonContainer">
    <button :id="id" class="headerButton" :class="headerButtonStyle">
      <img :src="icon" :alt="alt" class="icon" />
    </button>
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/runtime-core'
export default {
  name: 'ButtonHeader',
  props: {
    id: String,
    alt: String,
    icon: String,
    isLandscape: Boolean,
    isActive: Boolean
  },
  setup(props) {
    const headerButtonStyle = ref('')

    onMounted(() => { 
      headerButtonStyle.value = (props.isLandscape ? 'hbLandscape ' : 'hbPortrait ') + (props.isActive ? 'hbActive' : '') 
    })

    watch(() => [props.isLandscape, props.isActive], ([landscape, active]) => { 
      headerButtonStyle.value = (landscape ? 'hbLandscape ' : 'hbPortrait ') + (active ? 'hbActive' : '') 
    })

    return { headerButtonStyle }
  }
}
</script>

<style scoped>
.buttonContainer {
  display: contents;
}

.headerButton {
  border: none;
  border-radius: 100%;
  margin: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  background-color: var(--blackColor);
  -webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
  user-select: none;
  pointer-events: auto;
  z-index: 5;
}

.hbActive {
  background-color: var(--redColor);
}

.hbLandscape {
  width: 7vh;
  height: 7vh;
}

.hbPortrait {
  width: 4vh;
  height: 4vh;
}

.icon {
  height: 60%;
  width: 60%;
  -webkit-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	-khtml-user-drag: none;
}
</style>
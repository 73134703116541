<template>
  <button
    tag="button"
    class="buttonMenu"
    :class="active ? 'active' : ''"
    @click="$emit('selectApp', promoTools.appName)"
  >
    <div class="iconContainer">
      <img :src="active ? promoTools.iconSelect : promoTools.icon" :alt="`${promoTools.appName} Icon`">
    </div>
    <span class="title">{{ promoTools.title || '' }}</span>
  </button>
</template>

<script>
export default {
  name: 'ButtonMenu',
  emits: ['selectApp'],
  props: {
    active: Boolean,
    promoTools: Object
  },
  setup() { }
}
</script>

<style>
.buttonMenu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
  background: #0e0e0e;
  border: 1px solid var(--greyColor);
  cursor: pointer;
  user-select: none;
  pointer-events: auto;
  color: #868686;
}
.buttonMenu:hover {
  border: 2px solid var(--redColor);
}

.buttonMenu:active,
.active {
  font-weight: bold;
  color: #F199C0;
  background: var(--redColor);
}

.iconContainer > img {
  height: 100%;
  -webkit-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	-khtml-user-drag: none;
}

.iconContainer{
  display: flex;
  justify-content: center;
  height: 50%;
  aspect-ratio: 1;
}

.title {
  margin-top: 2px;
  font-size: 1.8vh;
  word-break: break-all;
  font-family: "Ubuntu-Regular";
  text-transform: capitalize;
}
</style>
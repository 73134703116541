import { createStore } from 'vuex';
import CommManager from '../communication/commManager';

const store = createStore({
  state() {
    return {
      initializedApp: false,
      initializeGameData: {},
      initializeSidePanelData: {},
      isSpinning: false,
      launchingData: {},
      messageReceived: {},
      messageSent: {},
      primaryFrame: null,
      primaryFrameOrigin: null,
      screenData: {},
      openPanel: false,
      visiblePanel: false,
      showMenu: false,
      showHelp: false,
      helpTexts: [],
      readyToPlay: false,
      promoToolActive: '',
      keepOnScreen: false,
      gameStarted: false,
      touchSidePanel: true,
      selectedPage: 0,
      messageFromWidget: ''
    }
  },
  mutations: {
    setInitializedApp(state, param) {
      state.initializedApp = param;
    },
    setInitializeGameData(state, data) {
      state.initializeGameData = data
    },
    setInitializeSidePanelData(state, data) {
      state.initializeSidePanelData = data;
    },
    setPrimaryFrame(state, source) {
      state.primaryFrame = source;
    },
    setPrimaryFrameOrigin(state, origin) {
      state.primaryFrameOrigin = origin;
    },
    setLaunchingData(state, params) {
      state.launchingData = params;
    },
    setMessageReceived(state, mesagge) {
      state.messageReceived = mesagge;
    },
    setMessageSent(state, mesagge) {
      state.messageSent = mesagge;
    },
    setOpenPanel(state, status) {
      state.openPanel = status;
    },
    setVisiblePanel(state, status) {
      state.visiblePanel = status;
    },
    setShowMenu(state, status) {
      state.showMenu = status;
    },
    setShowHelp(state, status) {
      state.showHelp = status;
    },
    setHelpText(state, data) {
      state.helpTexts = [...state.helpTexts, data];
    },
    setScreenData(state, data) {
      state.screenData = data;
    },
    setReadyToPlay(state, status) {
      state.readyToPlay = status;
    },
    setGameStarted(state, status) {
      state.gameStarted = status;
    },
    setKeepOnScreen(state, status) {
      state.keepOnScreen = status;
    },
    setTouchSidePanel(state, status) {
      state.touchSidePanel = status;
    },
    setPromoToolActive(state, appName) {
      state.promoToolActive = appName;
    },
    setIsSpinning(state, statusPlay) {
      state.isSpinning = statusPlay;
    },
    setSelectedPage(state, value) {
      state.selectedPage = value;
    }
  },
  getters: {
    getPrimaryFrame(state) {
      return state.primaryFrame;
    },
    getPrimaryFrameOrigin(state) {
      return state.primaryFrameOrigin;
    },
  },
  actions: {
    initializeApp(context) {
      const launchingData = CommManager.getLaunchData();
      context.commit('setInitializedApp', true);
      context.commit('setLaunchingData', launchingData);

      // Start Listening for events
      CommManager.startMessagesListener(this);
    },
    setMessageReceived(context, data) {
      context.commit('setMessageReceived', data);
    },
    setPrimaryFrameOrigin(context, origin) {
      context.commit('setPrimaryFrameOrigin', origin);
    },
    sendMessage(context, data) {
      context.commit('setMessageSent', data);
      CommManager.sendMessage(this, data);
    },
    screenData(context, data) {
      context.commit('setScreenData', data);
    },
    setOpenPanel(context, status) {
      context.commit('setOpenPanel', status);
    },
    setReadyToPlay(context, status) {
      context.commit('setReadyToPlay', status);
    },
    setGameStarted(context, status) {
      context.commit('setGameStarted', status);
    },
    setKeepOnScreen(context, status) {
      context.commit('setKeepOnScreen', status);
    },
    setTouchSidePanel(context, status) {
      context.commit('setTouchSidePanel', status);
    },
    setVisiblePanel(context, status) {
      context.commit('setVisiblePanel', status);
    },
    setInitializeGameData(context, data) {
      context.commit('setInitializeGameData', data);
    },
    setInitializeSidePanelData(context, data) {
      context.commit('setInitializeSidePanelData', data);
    },
    setPromoToolActive(context, appName) {
      context.commit('setPromoToolActive', appName);
    },
    setShowMenu(context, status) {
      context.commit('setShowMenu', status);
    },
    setShowHelp(context, status) {
      context.commit('setShowHelp', status);
    },
    setHelpText(context, data) {
      context.commit('setHelpText', data);
    },
    setIsSpinning(context, statusPlay) {
      context.commit('setIsSpinning', statusPlay);
    },
    setSelectedPage(context, value) {
      context.commit('setSelectedPage', value);
    }
  },
  modules: { },
});

export default store;
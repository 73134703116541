<template>
  <div class="container" :class="containerStyle">
    <div class="widgetContainer">
      <ScratchALot 
        @msgToGame="handleWidgetEvent"
        @msgToSidePanel="handleWidgetMessage"
        :jsonMsg="msgToWidget" 
        :screenData="screenData"
        :locale="locale"
        :isTouchSP="touchSidePanel"
        :isActive="promoToolActive === 'scratch'"
        v-if="isAvailable('scratch')"
        v-show="promoToolActive === 'scratch'" />
      <BuyAFeature
        @msgToGame="handleWidgetEvent"
        @msgToSidePanel="handleWidgetMessage"
        :jsonMsg="msgToWidget"
        :screenData="screenData"
        :gameName="gameName"
        :locale="locale"
        :isActive="promoToolActive === 'buyafeature'"
        v-if="isAvailable('buyafeature')"
        v-show="promoToolActive === 'buyafeature'" />
      <IntergameJackpots 
        @msgToGame="handleWidgetEvent"
        @msgToSidePanel="handleWidgetMessage"
        :gmapiEvent="msgToWidget" 
        :screenData="screenData"
        :localeData="locale"
        :isActive="promoToolActive === 'jackpots'"
        :isSidePanelOpen="openPanel"
        v-if="isAvailable('jackpots')"
        v-show="promoToolActive === 'jackpots'" />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref, watch } from '@vue/runtime-core'
import { ScratchALot } from '@vibragaming-gitlab/scratch-a-lot'
import { BuyAFeature } from '@vibragaming-gitlab/buy-a-feature'
import { IntergameJackpots } from '@vibragaming-gitlab/intergame-jackpots'
import WrapperContent from '../js/WrapperContent'
export default {
  name: 'PromoToolsContainer',
  emits: ['notifyEvent'],
  props: {
    screenData: Object,
    msgToWidget: Object,
    promoToolActive: String,
    locale: String,
    touchSidePanel: Boolean
  },
  components: {
    ScratchALot, BuyAFeature, IntergameJackpots
  },
  setup(props) {

    const store = useStore();
    const containerStyle = ref('');
    const openPanel = computed(() => store.state.openPanel);
    const widgetsData = computed(() => store.state.initializeSidePanelData.widgets);
    const gameName = computed(() => store.state.initializeSidePanelData.gameName);

    watch(() => props.screenData, () => { handleStyleChange() });

    watch(widgetsData, (data) => { handleStyleChange(data) });
    
    const handleWidgetEvent = (data) => { WrapperContent.sendMessage(store, data) };

    const handleWidgetMessage = (data) => {
      switch(data.event) {
        case 'sendHelpText':
          store.dispatch('setHelpText', data.value); 
          break;
        case 'jackpotAwarded':
          store.dispatch('setPromoToolActive', 'jackpots');
          if(!WrapperContent.isLandscapeScreen()) {
            store.dispatch('setShowMenu', false);
          }
          break;
      }
    };

    const handleStyleChange = (widgets) => {
      const wLength = widgets ? widgets.length : 0;
      const isLandscape = WrapperContent.isLandscapeScreen();
      containerStyle.value = isLandscape ? (wLength > 4 ? 'smallLandscape' : (wLength > 1 ? 'middleLandscape' : (props.touchSidePanel ? 'bigLandscape' : 'kioskNoTouchLandscape'))) : 'heightPortrait';
    };

    const isAvailable = (name) => {
      const widgets = widgetsData.value;
      return widgets && widgets.find(widget => widget.appName === name) !== undefined;
    };

    return {
      containerStyle,
      openPanel,
      gameName,
      handleWidgetEvent,
      handleWidgetMessage,
      isAvailable
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  color: white;
  pointer-events: none;
}
.smallLandscape { 
  height: 83%;
}
.middleLandscape {
  height: 86%;
}
.bigLandscape { 
  height: 94%;
}
.kioskNoTouchLandscape {
  height: 100%;
}
.heightPortrait {
  height: 100%;
}
.widgetContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: flex-start;
  position: relative;
}
</style>
<template>
  <mainView />
</template>

<script>
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex';
import mainView from './view/mainView.vue';

export default {
  name: 'App',
  components: {
    mainView,
  },
  setup() {
    const store = useStore();
   
    const initializeApp = () => {
      store.dispatch('initializeApp');
    };

    onMounted(() => {
      initializeApp();
    });
  },
}
</script>
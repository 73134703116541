<template>
  <div class="mainContainer">
    <aside
      id="side-panel"
      class="spContainer"
      :class="`${visibilityStyle} ${orientationStyle}`"
      v-show="showSidePanel"
    >
      <Header
        v-if="touchSidePanel"
        :isShowingMenu="showMenu"
        :isLandscape="landscapeScreen"
        :notification="notificationTitle"
        :showButtons="hasSliderMenu"
        @backMenu="handleBackMenu"
        @showHelp="handleShowHelp"
      />
      <HelpDialog 
        v-show="showHelp" 
        :isLandscape="landscapeScreen"
        :locale="locale"
        :promoToolActive="promoToolActive"
        @showHelp="handleShowHelp" />
      <PromoToolsContainer
        :screenData="screenData"
        :msgToWidget="messageToSP"
        :promoToolActive="promoToolActive"
        :locale="locale"
        :touchSidePanel="touchSidePanel"
        @notifyEvent="handleNotifications"
        v-show="landscapeScreen || !landscapeScreen && (!showMenu || !hasSliderMenu)"
      />
      <KeepBar
        v-if="touchSidePanel"
        v-show="landscapeScreen"
        :locale="locale"
        @handleKeepOnScreen="handleKeepOnScreen"
      />
      <SliderMenu
        v-if="hasSliderMenu"
        v-show="showMenu"
        :isLandscape="landscapeScreen"
        :promoToolsData="promoToolsData"
      />
      <NotificationBar v-if="hasSliderMenu && !landscapeScreen && showMenu" />
      <PaginationBar v-if="hasPaginationBar" v-show="showMenu" :isLandscape="landscapeScreen" />
      <OpenCloseButton :isLandscape="landscapeScreen" />
    </aside>
    <main class="gameContainer" :class="styleGameContainer">
      <GameContainer :urlGame="urlGame" />
    </main>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onBeforeMount, ref, watch } from '@vue/runtime-core';
import GameContainer from '../components/GameContainer.vue';
import PromoToolsContainer from '../components/PromoToolsContainer.vue';
import WrapperContent from '../js/WrapperContent';
import SliderMenu from '../components/SliderMenu.vue';
import Header from '../components/Header.vue';
import KeepBar from '../components/KeepBar.vue';
import PaginationBar from '../components/PaginationBar.vue';
import NotificationBar from '../components/NotificationBar.vue';
import HelpDialog from '../components/HelpDialog.vue';
import OpenCloseButton from '../components/OpenCloseButton.vue';
import { KIOSK_DEFAULT, KIOSK_NOTOUCH } from '../constants/index';
export default {
  name: 'mainView',
  components: {
    GameContainer,
    Header,
    PromoToolsContainer,
    KeepBar,
    SliderMenu,
    NotificationBar,
    PaginationBar,
    HelpDialog,
    OpenCloseButton
},
  setup() {
    const store = useStore();
    const landscapeScreen = ref(false);
    const promoToolsData = ref([]);
    const visibilityStyle = ref('');
    const orientationStyle = ref('');
    const styleGameContainer = ref('');
    const notificationTitle = ref('');
    const urlGame = ref('');
    const isMobile = ref(false);
    const hasSliderMenu = ref(false);
    const hasPaginationBar = ref(false);
    const autoCloseTimeout = ref(0);
    const initSidePanelData = computed(() => store.state.initializeSidePanelData);
    const launchingData = computed(() => store.state.launchingData);
    const locale = computed(() => store.state.launchingData.locale);
    const channel = computed(() => store.state.launchingData.channel);
    const touchSidePanel = computed(() => store.state.touchSidePanel);
    const messageToSP = computed(() => store.state.messageReceived);
    const openPanel = computed(() => store.state.openPanel);
    const promoToolActive = computed(() => store.state.promoToolActive);
    const screenData =  computed(() => store.state.screenData);
    const showMenu = computed(() => store.state.showMenu);
    const showHelp = computed(() => store.state.showHelp);
    const showSidePanel = computed(() => store.state.visiblePanel);
    const readyToPlay = computed(() => store.state.readyToPlay);
    const keepOnScreenActive = computed(() => store.state.keepOnScreen);

    const launchGame = () => {
      urlGame.value = WrapperContent.getUrlGame(launchingData.value);
    };

    const setScreenData = () => {
      const screenData = WrapperContent.getScreenData();
      store.dispatch("screenData", screenData);
    };

    const resizeGameContainer = () => {
      let newStyle = '';

      if(channel.value === KIOSK_NOTOUCH) {
        if(landscapeScreen.value) {
          newStyle = openPanel.value ? 'keepOnScreenActive' : 'keepOnScreenDisable';
        } else {
          newStyle = openPanel.value ? 'keepOnScreenPortraitActive' : 'keepOnScreenPortraitDisable';
        }
      } else {
        if(landscapeScreen.value) {
          newStyle = (keepOnScreenActive.value) ? 'keepOnScreenActive' : 'keepOnScreenDisable';
        } else {
          newStyle = 'keepOnScreenFullPortrait';
        }
      }
      
      return newStyle;
    };

    const handleKeepOnScreen = () => {
      store.dispatch('setKeepOnScreen', !keepOnScreenActive.value);
      styleGameContainer.value = resizeGameContainer();
    };

    const handleBackMenu = () => {
      const isLandscape = landscapeScreen.value;
      const state = (isLandscape) ? true : !showMenu.value;
      store.dispatch('setShowMenu', state);
    };

    const handleShowHelp = () => {
      store.dispatch('setShowHelp', !showHelp.value);
    };

    const handleOpenPanel = () => {
      store.dispatch('setOpenPanel', !openPanel.value);
    };

    const handleNotifications = (title) => {
      notificationTitle.value = title;
    };

    const parseInitSidePanelData = (data) => {
      if (typeof data === 'undefined') { return }

      if (typeof data.defaultOpen === 'boolean') {
        store.dispatch('setOpenPanel', !data.defaultOpen);
      }

      if (typeof data.autoCloseTimeout === 'number') {
        autoCloseTimeout.value = data.autoCloseTimeout;
      } else if (typeof data.autoCloseTimeout === 'string') {
        autoCloseTimeout.value = -1;
      }

      if(data.defaultOpen && Object.hasOwn(data, 'keepOnScreen')) { 
        store.dispatch('setKeepOnScreen', data.keepOnScreen); 
      }

      document.title = data.gameName ? data.gameName : 'Slot Game';

      if(data.widgets && data.widgets.length > 0) {
        let promoToolActive = '';
        
        const widgetsData = data.widgets.map((app) => {
          let icon = '';
          let iconSelect = '';
          try {
            icon = require(`../assets/images/${app.appName}.svg`);
            iconSelect = require(`../assets/images/${app.appName}_select.svg`);
          } catch (error) {
            icon = require(`../assets/images/scratch.svg`);
            iconSelect = require(`../assets/images/scratch_select.svg`);
          }
          app.icon = icon;
          app.iconSelect = iconSelect

          if (app.activeDefault) {
            promoToolActive = app.appName;
          }

          return app;
        });

        promoToolsData.value = widgetsData;
        hasSliderMenu.value = widgetsData.length > 1;
        hasPaginationBar.value = widgetsData.length > 4;
        store.dispatch('setShowMenu', widgetsData.length > 1);
        store.dispatch('setPromoToolActive', promoToolActive);
      }
    };

    const changeSidePanelStyle = () => {
      if(landscapeScreen.value) {
        visibilityStyle.value = openPanel.value ? 'spVisibleX' : 'spHiddenX'; 
        orientationStyle.value = 'spLandscapeOpen' + (isMobile.value ? ' spMobile' : '');
      }
      else {
        if(channel.value === KIOSK_NOTOUCH) {
          visibilityStyle.value = openPanel.value ? 'spVisibleKioskNTY' : 'spHiddenKioskNTY';
          orientationStyle.value = 'spPortraitOpenKioskNT';
        } else {
          visibilityStyle.value = openPanel.value ? 'spVisibleY' : 'spHiddenY';
          orientationStyle.value = 'spPortraitOpen' + (isMobile.value ? ' spMobile' : '');
        }
      }
    };

    watch(launchingData, () => {
      launchGame();
    })

    watch(screenData, () => {
      landscapeScreen.value = WrapperContent.isLandscapeScreen();
      styleGameContainer.value = resizeGameContainer();
      changeSidePanelStyle();
    });

    watch(landscapeScreen, (value) => { 
      if(!value && channel.value !== KIOSK_NOTOUCH) { 
        store.dispatch('setKeepOnScreen', false); 
      }
      if(hasSliderMenu.value) { handleBackMenu() }
    })

    watch(openPanel, () => {
      if(channel.value === KIOSK_NOTOUCH) {
        styleGameContainer.value = resizeGameContainer();
      }
      changeSidePanelStyle();
    });

    watch(channel, (value) => {
      if(value.includes(KIOSK_DEFAULT)) {
        WrapperContent.initializeKioskCommunication();
        store.dispatch('setTouchSidePanel', value !== KIOSK_NOTOUCH); 
      }
    });

    watch(readyToPlay, (status) => {
      if(status && !keepOnScreenActive.value && autoCloseTimeout.value >= 0) { 
        setTimeout(handleOpenPanel, autoCloseTimeout.value * 1000); 
      }
    });

    watch(messageToSP, (message) => {
      WrapperContent.processMessageApi(store, message);
    });

    watch(initSidePanelData, (data) => {
      parseInitSidePanelData(data);
    });

    watch(showSidePanel, () => {
      handleOpenPanel();
      styleGameContainer.value = resizeGameContainer();
    });

    onBeforeMount(() => {
      WrapperContent.setEventOnResize(store);
      landscapeScreen.value = WrapperContent.isLandscapeScreen();
      isMobile.value = WrapperContent.isMobileDevice();
      setScreenData();
    })

    return {
      handleOpenPanel,
      handleKeepOnScreen,
      handleBackMenu,
      handleNotifications,
      handleShowHelp,
      keepOnScreenActive,
      screenData,
      landscapeScreen,
      touchSidePanel,
      messageToSP,
      notificationTitle,
      promoToolsData,
      promoToolActive,
      showSidePanel,
      showMenu,
      showHelp,
      visibilityStyle,
      orientationStyle,
      styleGameContainer,
      urlGame,
      isMobile,
      hasSliderMenu,
      hasPaginationBar,
      locale
    };
  }
}
</script>

<style>
.mainContainer {
  display: flex;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  margin: 0;
  padding: 0;
  position: absolute;
  background: rgb(46,54,35);
  background: linear-gradient(45deg, rgba(46,54,35,1) 0%, rgba(25,21,24,1) 50%, rgba(51,22,32,1) 100%);
  background: -moz-linear-gradient(45deg, rgba(46,54,35,1) 0%, rgba(25,21,24,1) 50%, rgba(51,22,32,1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(46,54,35,1) 0%, rgba(25,21,24,1) 50%, rgba(51,22,32,1) 100%);
  transition: all 1s ease;
  overflow: hidden;
  --redColor: #9A0142;
  --greyColor: #1A1B1B;
  --greyLighColor: #272727;
  --blackColor: #010101;
}

.gameContainer {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 0;
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  user-select: none;
}

.spContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  margin: 0;
  padding: 0;
  opacity: 0.98;
  background-color: var(--blackColor);
  box-shadow: 3px 0 3px 0 var(--greyColor);
  touch-action: none;
}

.spMobile {
  pointer-events: none;
}

.keepOnScreenActive {
  -webkit-animation: keepOnScreenActiveAnim 500ms forwards;
  -moz-animation: keepOnScreenActiveAnim 500ms forwards;
  animation: keepOnScreenActiveAnim 500ms forwards;
}

.keepOnScreenDisable {
  -webkit-animation: keepOnScreenDisableAnim 500ms forwards;
  -moz-animation: keepOnScreenDisableAnim 500ms forwards;
  animation: keepOnScreenDisableAnim 500ms forwards;
}

.keepOnScreenPortrait {
  height: 70%;
  align-self: flex-end;
}

.keepOnScreenFullPortrait {
  align-self: stretch;
}

.keepOnScreenPortraitActive {
  -webkit-animation: keepOnScreenActiveAnimY 500ms forwards;
  -moz-animation: keepOnScreenActiveAnimY 500ms forwards;
  animation: keepOnScreenActiveAnimY 500ms forwards;
}

.keepOnScreenPortraitDisable {
  -webkit-animation: keepOnScreenDisableAnimY 500ms forwards;
  -moz-animation: keepOnScreenDisableAnimY 500ms forwards;
  animation: keepOnScreenDisableAnimY 500ms forwards;
}

.spVisibleX {
  transform: translateX(0%);
  transition: all 500ms ease;
}

.spVisibleY {
  transform: translateY(0%);
  transition: all 500ms ease;
}

.spHiddenX {
  transform: translateX(-100%) !important;
  transition: all 600ms ease;
}

.spHiddenY {
  transform: translateY(-100%) !important;
  transition: all 600ms ease;
}

.spVisibleKioskNTY {
  transform: translateY(0%);
  transition: all 500ms ease;
}

.spHiddenKioskNTY {
  transform: translateY(-100%) !important;
  transition: all 300ms 250ms ease;
}

.spLandscapeOpen {
  width: 20%;
  max-width: 20%;
  height: 100%;
  position: absolute;
}

.spPortraitOpen {
  width: 100%;
  height: 30%;
  max-height: 30%;
  position: absolute;
}

.spPortraitOpenKioskNT {
  width: 100%;
  height: 25%;
  max-height: 25%;
  position: absolute;
}

@keyframes keepOnScreenActiveAnim {
  from {
    width: 100%;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    transform: translateX(0%);
  }
  to {
    width: 80%;
    -webkit-transform: translateX(25%);
    -moz-transform: translateX(25%);
    transform: translateX(25%);
  }
}

@keyframes keepOnScreenDisableAnim {
  from {
    width: 80%;
    -webkit-transform: translateX(25%);
    -moz-transform: translateX(25%);
    transform: translateX(25%);
  }
  to {
    width: 100%;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    transform: translateX(0%);
  }  
}

@keyframes keepOnScreenActiveAnimY {
  from {
    height: 100%;
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    transform: translateY(0%);
  }
  to {
    height: 75%;
    -webkit-transform: translateY(33.3%);
    -moz-transform: translateY(33.3%);
    transform: translateY(33.3%);
  }
}

@keyframes keepOnScreenDisableAnimY {
  0% {
    height: 75%;
    -webkit-transform: translateY(33.3%);
    -moz-transform: translateY(33.3%);
    transform: translateY(33.3%);
  }
  50% {
    height: 100%;
    transform: translateY(25%);
  }
  100% {
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    transform: translateY(0%);
  }
}
</style>
import {
  CONSTANTS,
  EVENT_RGS_RESPONSE,
  EVENT_PLAY_ENDED,
  EVENT_PLAY_STARTED,
  EVENT_BLOCK_GUI_STATUS_CHANGED,
  EVENT_ACTIVE_STATUS_CHANGED,
  EVENT_GAME_READY_TO_PLAY,
  EVENT_SIDE_PANEL,
  EVENT_TOGGLE_SIDEPANEL_STATE,
  KIOSK_NOTOUCH
} from "../constants";

const WrapperContent = {
  /******************** START MESSAGE ********************/
  activeStatusChanged: (event, value) => {
    if (typeof event === 'undefined') { return false; }

    const eventData = {
      toFrame: "primary",
      event,
      value: value || null,
    };

    return eventData
  },

  blockGUIStatusChanged: (store, value) => {
    const event = (value) ? "blockGUI" : "unblockGUI";
    const eventData = {
      toFrame: "primary",
      event,
    };

    WrapperContent.sendMessage(store, eventData);
  },

  getUrlGame: (launchingData) => {
    let gameURLPrimary = '';

    if (typeof launchingData !== 'object') { return gameURLPrimary; }
    if (launchingData.gameId) {
      gameURLPrimary = launchingData.pathLauncher + 'GameManager.php?';     
      gameURLPrimary += 'gameId=' + launchingData.gameId;
      gameURLPrimary += '&siteId=' + launchingData.siteId;
      gameURLPrimary += '&userId=' + launchingData.userId;
      gameURLPrimary += '&gameMode=' + launchingData.gameMode;
      gameURLPrimary += '&currency=' + launchingData.currency;
      gameURLPrimary += '&channel=' + launchingData.channel;
      gameURLPrimary += '&locale=' + launchingData.locale;
      gameURLPrimary += '&token=' + launchingData.token;
      gameURLPrimary += '&music=' + launchingData.music;
      gameURLPrimary += '&sfx=' + launchingData.sfx;
      gameURLPrimary += '&origin=' + launchingData.origin;
      gameURLPrimary += '&luid=' + launchingData.luid;
      gameURLPrimary += '&lobbyURL=' + launchingData.lobbyUrl;
      gameURLPrimary += '&lobbyTarget=_parent';
      gameURLPrimary += '&isIframe="true"';
      gameURLPrimary += '&timedActions=' + launchingData.timedActions;
      if(launchingData.rm) {
        gameURLPrimary += '&rm=' + launchingData.rm;
      }
      if(launchingData.layout) {
        gameURLPrimary += '&layout=' + launchingData.layout;
      }
    }

    return gameURLPrimary;
  },

  sendBlockStatus: (store) => {
    const eventData = {
      toFrame: "primary",
      event: "blockGUI",
    };

    WrapperContent.sendMessage(store, eventData);
  },

  sendUnBlockStatus: (store) => {
    const eventData = {
      toFrame: "primary",
      event: "unblockGUI",
    };

    WrapperContent.sendMessage(store, eventData);
  },

  focusGame: (store) => {
    const primaryFrame = store.getters.getPrimaryFrame;
    const primaryFrameSource = primaryFrame?.contentWindow;
    /*const eventData = {
      toFrame: "primary",
      event: "focusGame",
    };*/
    
    //WrapperContent.sendMessage(store, eventData);

    try {
      primaryFrameSource && primaryFrameSource.focus();
    } catch(error) {
      console.log(error);
    }
  },

  initializeKioskCommunication: () => {
    const targetURL = document?.referrer;
    if(targetURL) { window.parent.postMessage(JSON.stringify({ msgId: 'windowInitialized' }), targetURL) }
  },

  onRgsResponse: (store, data) => {
    // Process initialize Game Data
    const initData = data.filter((message) => message.method === 'initializeGame')
    if (initData && initData.length > 0) {
      store.dispatch("setInitializeGameData", initData[0]);
    }
  },

  getDataSidePanel: (store, data) => {
    const sidePanelData = data;
    if (sidePanelData && sidePanelData.widgets && sidePanelData.widgets.length > 0) {
      store.dispatch("setInitializeSidePanelData", sidePanelData);
    }
  },

  gameReadyToPlay: (store) => {
    store.dispatch("setVisiblePanel", true);
    store.dispatch("setReadyToPlay", true);
  },

  onPlayStarted: (store, playStatus) => {
    store.dispatch('setIsSpinning', playStatus);
    const gameStarted = store.state.gameStarted;
    const keepOnScreen = store.state.keepOnScreen;
    const autoClose = store.state.initializeSidePanelData.autoCloseTimeout;
    if(!gameStarted && !keepOnScreen && autoClose === 'spin') {
      store.dispatch('setOpenPanel', false);
      store.dispatch('setGameStarted', true);
    }
  },

  onPlayEnded: (store, playStatus) => {
    store.dispatch('setIsSpinning', playStatus);
  },

  sendMessage: (store, eventData) => {
    if (typeof eventData === 'undefined') { return; }
    store.dispatch("sendMessage", eventData);
    store.dispatch('setGameStarted', true);
  },

  onToggleSidePanelState: (store) => {
    const isKioskNT = store.state.launchingData.channel === KIOSK_NOTOUCH;
    if(!isKioskNT && !store.state.keepOnScreen || isKioskNT) {
      store.dispatch("setOpenPanel", !store.state.openPanel);
    }
  },

  processMessageApi: (store, message) => {
    // let response = false;

    switch (message?.msgId) {
      case EVENT_BLOCK_GUI_STATUS_CHANGED:
        WrapperContent.blockGUIStatusChanged(store, message);
        break;
      
      case EVENT_RGS_RESPONSE:
        WrapperContent.onRgsResponse(store, message.value);
        break;
      
      case EVENT_PLAY_STARTED:
        WrapperContent.onPlayStarted(store, message);
        break;

      case EVENT_PLAY_ENDED:
        WrapperContent.onPlayEnded(store, message);
        break;

      case EVENT_GAME_READY_TO_PLAY:
        WrapperContent.gameReadyToPlay(store, message);
        break;

      case EVENT_SIDE_PANEL:
        WrapperContent.getDataSidePanel(store, message.value);
        break;

      case EVENT_TOGGLE_SIDEPANEL_STATE:
        WrapperContent.onToggleSidePanelState(store);
        break;

      case EVENT_ACTIVE_STATUS_CHANGED:
        /* if (contentAPI.activeStatus) {
          sendBlockStatus();
        } else {
          // isSpinning = false;
          sendUnBlockStatus()
        } */
        break;
    
      default:
        break;
    }
  },
  /******************** END MESSAGE ********************/
  setEventOnResize: (store) => {
    window.onresize = () => {
      const screenData = WrapperContent.getScreenData();
      store.dispatch("screenData", screenData);
    };
  },
  
  getScreenData: () => {
    const width = document?.documentElement?.clientWidth || window.innerWidth || 0;
    const height = document?.documentElement?.clientHeight || window.innerHeight || 0;
    const orientation = ( width >= height ) ? CONSTANTS.LANDSCAPE_SCREEN : CONSTANTS.PORTRAIT_SCREEN;
    const ratio = (height > 0) ? (Math.round(width / height * 100) / 100) : 0;
    const isIOS = ((navigator.userAgent.match(/(iPhone|iPad|iPod|Mac)/i)) != null);
    const isMobile = ((navigator.userAgent.match(/(Android|webOS|iPhone|iPad|iPod|Windows Phone|BlackBerry)/i)) != null);

    return {
      width,
      height,
      orientation,
      ratio,
      isIOS,
      isMobile
    };
  },

  isLandscapeScreen: () => {
    const screen = WrapperContent.getScreenData();
    return screen.orientation === CONSTANTS.LANDSCAPE_SCREEN;
  },

  isMobileDevice: () => {
    const screen = WrapperContent.getScreenData();
    return screen.isMobile;
  },
  
  
/******************** START CASHOUT ********************/
  addEventToExecute: (object, eventName, functionToExecute) => {
	if (object.addEventListener){
		object.addEventListener(eventName, functionToExecute, {passive: true});
	} else if (object.attachEvent) {
		object["e" + eventName + functionToExecute] = functionToExecute;
		object[eventName + functionToExecute] = () => {
			object["e" + eventName + functionToExecute](window.event);
		};

		object.attachEvent("on" + eventName, object[eventName + functionToExecute]);
	} else {
		object["on" + eventName] = object["e" + eventName + functionToExecute];
	}
},

  removeEventToExecute: (object, eventName, functionToExecute) => {
    if (object.removeEventListener) {
      object.removeEventListener(eventName, functionToExecute, {passive: true});
    } else if (object.detachEvent) {
      object.detachEvent("on" + eventName, object[eventName + functionToExecute]);
    } else {
      object["on" + eventName] = null;
    }
  },

/******************** END CASHOUT ********************/
};

export default WrapperContent;
<template>
  <iframe
   name="primary"
   id="primary"
   ref="primaryFrame"
   width="100%"
   height="100%"
   class="iframe"
   frameborder="0"
   scrolling="no"
   :src='urlGame'
  />
</template>

<script>
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
export default {
  name: 'GameContainer',
  props: {
    urlGame: String,
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      const frame = document.getElementById("primary");
      if (frame && frame.contentWindow) {
        store.commit('setPrimaryFrame', frame);
      }
    });
  }
}
</script>

<style>
.iframe {
  width: 100%;
  height: 100%;
}
</style>
<template>
  <div class="paginationBar" :class="barStyle">
    <span v-for="(page, index) in pages" :key="index" class="pageButton" :class="buttonStyle(index)" @click="handlePageButtonClick(index)" />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { onMounted, computed, ref, watch } from '@vue/runtime-core';
export default {
  name: 'PaginationBar',
  emits: [''],
  props: {
    isLandscape: Boolean
  },
  setup(props) {
    const store = useStore();
    const pages = ref(2);
    const barStyle = ref('');
    const selectedPage = computed(() => store.state.selectedPage);
    // TODO: Calcular la cantidad de páginas en base a al número de widgets

    onMounted(() => { 
      setBarStyle(); 
    })

    watch(() => props.isLandscape, () => { setBarStyle(); })

    const setBarStyle = () => { 
      barStyle.value = props.isLandscape ? 'pbLandscape' : 'pbPortrait'; 
    }

    const handlePageButtonClick = (index) => { 
      store.commit('setSelectedPage', index);
    }

    const buttonStyle = (index) => { 
      return selectedPage.value === index ? 'buttonSelected' : ''; 
    }

    return {
      pages,
      selectedPage,
      barStyle,
      handlePageButtonClick,
      buttonStyle
    };
  }
}
</script>

<style>
.paginationBar {
  background-color: var(--greyColor);
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
}

.pbLandscape {
  height: 3%;
}

.pbPortrait {
  height: 15%;
}

.pageButton {
  width: 1.5vh;
  height: 1.5vh;
  background-color: var(--blackColor);
  border-radius: 50%;
  margin: 0 10px;
  user-select: none;
  pointer-events: auto;
}

.pageButton:hover {
  cursor: pointer;
}

.buttonSelected {
  background-color: var(--redColor);
}
</style>